<template>
	<div id="tu-student-list">
		<el-row :gutter="20">
			<el-col :span="4">
			<SecondMenu />
			</el-col>
			<el-col :span="20">
				<!-- <div class="tu-title">
					<div>Students</div>
					<div></div>
				</div> -->
				<div class="global-search">
					<div class="global-search__title">{{ $t('search') }}</div>
					<div class="global-search__warp">
						<el-form :inline="true" class="global-search__warp__inline">
							<el-form-item :label="$t('Keyword')">
								<el-input v-model="params.keyword" placeholder="Please input" />
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSearchBtn">{{ $t('search') }}</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div v-if="tableData.length > 0 || loading" class="warp">
					<el-table 
					v-loading="loading"
					element-loading-text="Loading..."
					:data="tableData" 
					border 
					:show-header="showHeader">
					<el-table-column prop="name" :label="$t('Name')">
						<template #default="scope">
							<div class="detail">
								<div class="avatar_url">
									<el-avatar v-if="scope.row.avatar_url" :size="80" :src="scope.row.avatar_url"></el-avatar>
									<el-avatar v-else :size="80">{{ scope.row.slot }}</el-avatar>
								</div>
								<div class="info">
									<div class="name">{{ scope.row.nickname }}</div>
									<div v-if="scope.row.type == 2">{{ scope.row.tagline }}</div>
									<div v-if="scope.row.type == 1">{{ scope.row.content }}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column width="150" align="center" :label="$t('Operations')">
					  <template #default="scope">
							<div class="btn">
								<div>
									<el-button type="primary" size="mini" @click="handleGoChat(scope.$index, scope.row)">{{ $t('router.chat') }}</el-button>
								</div>
								<div>
									<el-button type="primary" size="mini" @click="handleBookCourseOpen(scope.$index, scope.row)">{{ $t('book-course') }}</el-button>
								</div>
							</div>
					  </template>
					</el-table-column>
					</el-table>	
					<div class="tu-pagination">
						<el-pagination 
							:hide-on-single-page="true" 
							background 
							layout="prev, pager, next" 
							:total="params.total"
							:page-size="20"
							@current-change="handlePageChange">
						</el-pagination>
					</div>
				</div>
				<div v-else>
					<el-empty description="No Data"></el-empty>
				</div>
			</el-col>
		</el-row>
		<BookCourse ref="bookCourse" />
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import BookCourse from '@/components/BookCourse'
import StudentApi from '@/api/student'

export default {
	name: "student",
	components: {
		SecondMenu,
		BookCourse
	},
	data() {
		return {
			loading: true,
			showHeader: true,
			tableData: [],
			params: {
				page: 1,
				total: 0,
				keyword: ''
			}
		}
	},
	created() {
		this.id = this.$route.params.id
		this.getApiListData()
	},
	methods: {
		handleBookCourseOpen(index, row) {
			let rowId = row.uid || 0;
			if(!!!rowId) {
				this.$message.error("Get student details exception");
				return false;
			}
			let param = {
				uid: row.uid,
				profile_id: row.profile_id
			}
			this.$refs.bookCourse.open(param);
		},
		handleGoChat(index, row) {
			let rowId = row.chat_id || 0;
			if(!!!rowId) {
				this.$message.error("Getting teacher details is abnormal");
				return false;
			}
			this.$router.push({ path: `/chat/${rowId}` })
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		getApiListData() {
			StudentApi.index(this.params).then((res) => {
				this.loading = false
				this.tableData = res.data
				this.params.total = res.meta.pagination.total
			});
		},
		onSearchBtn() {
			this.handlePageChange(1);
		}
	}
}
</script>
<style lang="less">
#tu-student-list {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.warp {
		.detail {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			.avatar_url {
				margin-right: 20px;
			}

			.info {
				div {
					line-height: 1.5;
				}

				.name {
					font-size: 18px;
				}
			}
		}

		.btn {
			display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		align-content: space-around;

		div {
			margin: 3px 0;
		}
		}
	}

	.tu-pagination {
		padding: 20px 0;
	}
}
</style>